<template>
  <base-form-item :field="field">
    <div
        class="view-body fieldUpload--container"
        element-loading-background="rgba(248, 249, 250, .5)"
    >
      <el-upload
          :accept="meta && meta.accept"
          :action="uploadURL"
          :auto-upload="meta && meta.autoUpload ? meta.autoUpload : false"
          :before-upload="beforeUpload"
          :class="meta && meta.class? meta.class : ''"
          :data="requestOptions"
          :drag="meta && meta.drag? meta.drag : true"
          :headers="headers"
          :limit="meta && meta.limit ? meta.limit : 0"
          :list-type="meta && meta.listType? meta.listType : 'text'"
          :multiple="meta && meta.multiple ? meta.multiple : true"
          :on-change="onChange"
          :on-error="onError"
          :on-preview="onPreview"
          :on-remove="onRemove"
          :on-success="onSuccess"
          :show-file-list="meta && meta.showFileList"
          :thumbnail-mode="meta && meta.thumbnailMode ? meta.thumbnailMode : false"
          :with-credentials="meta && meta.withCredentials ? meta.withCredentials : false"
          class="file--upload"
          ref="fileUploader"
      >
        <div
            class="el-upload__tip"
            slot="tip"
            v-if="meta && meta.maxSize"
        > {{ $t('system.files_size_less_then') }} <b>{{ meta.maxSize }} MB</b></div>
        <div
            class="el-upload__tip"
            slot="tip"
            v-if="meta && meta.limit"
        > {{ $t('system.files_count_limit') }} - <b>{{ meta.limit }}</b></div>
        <i class="el-icon-plus"></i>
      </el-upload>
      <ul
          class="fieldUpload--list"
          v-if="fileLists.length"
      >
        <li
            :key="item.id"
            class="el-upload-list__item"
            v-for="(item, index) in fileLists"
        >
          <span @click="onPreview(item)">{{ item.nameWithSize }}</span>
          <transition name="el-fade-in">
            <el-progress
                :percentage="downloadProgressPercent"
                :show-text="false"
                :stroke-width="3"
                status="success"
                v-if="item.downloading"
            ></el-progress>
          </transition>
          <i
              @click="removeItemFromFilesList(item, index)"
              class="el-icon-close file-delete-icon"
          ></i>
        </li>
      </ul>
      <LightBox
          :index="index"
          :items="galleries"
          :slideshow="false"
          @close="index = null"
          srcThumb="src"
          thumbsPosition="bottom"
          useZoomBar
      />
    </div>
  </base-form-item>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import gallery from './gallery';
import abstractForm from '../../mixin/index';
import notification from '../../../../../notification/notify';
import EventBus from '../../../../../eventBus';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  name: 'CreateOrUpdate',
  mixins: [abstractForm, gallery],
  components: {
    LightBox: () => import('vue-cool-lightbox'),
    BaseFormItem,
  },
  data() {
    return {
      uploadURL: '',
      newFiles: [],
      filesToDelete: [],
      headers: {Authorization: 'Bearer ' + localStorage.getItem('token')},
      requestOptions: {clientId: this.$store.getters['auth/clientId']},
    };
  },
  beforeMount() {
    this.setData(this.value);
  },
  created() {
    EventBus.$once('ISSUE_WAS_CREATED_OR_UPDATED', async (id) => {
      await this.generateUploadURL(id);
      await this.onIssueCreatedOrUpdated();
    });
  },
  beforeDestroy() {
    EventBus.$off('ISSUE_WAS_CREATED_OR_UPDATED');
  },
  methods: {
    async onIssueCreatedOrUpdated() {
      await this.removeFiles();
      await this.uploadFiles();
    },
    async uploadFiles() {
      if (this.newFiles && this.newFiles.length > 0) {
        this.$refs.fileUploader.submit();
      } else {
        EventBus.$emit('FILES_UPLOADED');
      }
    },
    async generateUploadURL(id) {
      if (this.meta.uploadURL) {
        const url = this.meta.uploadURL;
        this.uploadURL = process.env.VUE_APP_ROOT_API + '/' + url.replace('{id}', id);
      } else {
        this.uploadURL = '';
      }
    },
    async removeFiles() {
      if (this.filesToDelete.length > 0) {
        return axios.delete('issue/' + this.getIdTracker + '/attachment', {
          params: {
            list: this.filesToDelete,
          },
        });
      }
    },
    removeItemFromFilesList(item, index) {
      this.fileLists.splice(index, 1);
      this.filesToDelete.push(item.id);
    },
    async onRemove(file, fileList) {
      await this.setNewFilesList(fileList);
    },
    async onChange(file, fileList) {
      if (this.meta.maxSize && file.size >= this.meta.maxSize * 1048576) {
        notification.notify(
            file.name,
            this.$t('notify.file_is_too_large'),
            'warning');
        _.remove(fileList, (item) => {
          return item.uid === file.uid;
        });
      }
      await this.setNewFilesList(fileList);
    },
    async onSuccess(response, file, fileList) {
      await this.setNewFilesList(fileList);
      await this.checkIfFilesUploaded(this.newFiles);
    },
    setNewFilesList(fileList) {
      this.newFiles = _.cloneDeep(fileList);
    },
    async checkIfFilesUploaded(newFiles) {
      let finishedUpload = true;
      _.forEach(newFiles, (file) => {
        if (file.percentage !== 100) {
          finishedUpload = false;
        }
      });
      if (finishedUpload) {
        // setTimeout(() => {
        EventBus.$emit('FILES_UPLOADED');
        // }, 300);
      }
    },
    async onError(err, file, fileList) {
      console.log('err', err);
      this.setNewFilesList(fileList);
      EventBus.$emit('LOADING', false);
      notification.notify(
          this.$t('notify.error'),
          file.name + ' ' + this.$t('system.file_not_uploaded'),
          'error');
    },
    beforeUpload() {
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.file--upload {

  .el-upload {
    width: 100%;
  }

  .el-upload-dragger {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.fieldUpload--list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
  }
;
  /*.el-icon-close{*/
  /*  float: right;*/
  /*}*/
}
</style>
